<nav class="navbar fixed-top navbar-expand-lg navbar-dark mdb-color darken-1 scrolling-navbar white-text">
    <div class="container">
        <!-- Brand -->
        <a *ngIf="!seller" (click)="clickToNavigate('')" class="navbar-brand waves-effect" data-toggle="collapse" data-target=".navbar-collapse.show">
            <img src="../../assets/img/pageimg/logo.png" class="img-profile mr-2"> <strong>NGOAN Streetfood</strong>
        </a>
        <a *ngIf="seller" (click)="clickToCustomerPage('/shop/article/')" class="navbar-brand waves-effect" data-toggle="collapse" data-target=".navbar-collapse.show">
            <img src="{{seller.img[1]}}" class="img-profile mr-2"> <strong>{{seller.shopname}}</strong>
        </a>
        <!-- Collapse -->
        <button *ngIf="seller" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <!-- Links -->
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <!-- Left -->
            <ul *ngIf="seller" class="navbar-nav mr-auto">
                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <a (click)="clickToCustomerPage('/shop/article/')" class="nav-link waves-effect"><sub><mat-icon>restaurant</mat-icon></sub> Vorbestellen</a>
                </li>
                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <a (click)="clickToCustomerPage('/shop/reserve/')" class="nav-link waves-effect"><sub><mat-icon>today</mat-icon></sub> Reservieren</a>
                </li>
                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                    <a href="{{getTelefon()}}" class="nav-link waves-effect"><sub><mat-icon>phone</mat-icon></sub> {{seller.telefon}}</a>
                </li>
            </ul>
            <!-- Right -->
            <ul *ngIf="seller" class="navbar-nav nav-flex-icons">
                <!-- Nav Item - User Information -->
                <li class="nav-item dropdown no-arrow">
                    <a class="nav-link" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        🕗 Öffnungszeiten <i *ngIf="seller?.infotext" class="fa fa-info-circle blink_me red-text p-1"></i>
                    </a>
                    <!-- Dropdown - User Information -->
                    <div class="dropdown-menu dropdown-menu shadow animated--grow-in" aria-labelledby="userDropdown">
                        <small *ngIf="seller?.infotext">
                            <div class="row mx-1">
                                <div class="col">
                                    <i class="fa fa-info-circle red-text"></i> {{seller.infotext}}
                                </div>
                            </div>
                        </small>
                        <div class="dropdown-divider"></div>
                        <small *ngIf="seller">
                            <div *ngFor="let open of seller.openings" class="row mx-1">
                                <div class="col-3">
                                    {{days[open.day]}}
                                </div>
                                <div class="col-9">
                                    {{open.from.hour}}:{{open.from.minute}} {{open.to.hour}}:{{open.to.minute}}
                                </div>
                            </div>
                        </small>
                        <div class="dropdown-divider"></div>
                        <small *ngIf="seller">
                            <span class="mx-2">{{seller.street}} {{seller.house}}</span><br> 
                            <span class="mx-2">{{seller.zipcode}} {{seller.city}}</span>
                        </small>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>