<navbar></navbar>
<!-- alert.momponent.html -->
<div class="text-center my-auto ml-auto">
    <alertComponent></alertComponent>
</div>
<router-outlet></router-outlet>
<div class="container text-center">
    <hr class="my-4">
    <div class="pb-4">
    </div>
</div>
<!--Footer-->
<footer class="page-footer text-center font-small wow fadeIn">
    <div class="my-1">&nbsp;</div>
    <div class="pb-4">
        <a class="mx-1" (click)="clickShowPage('shop/info', 'kundenservice');">Kundenservice</a>
        <a class="mx-1" (click)="clickShowPage('shop/info', 'datenschutz');">Datenschutz</a>
        <a class="mx-1" (click)="clickShowPage('shop/info', 'agb');">AGB</a>
        <a class="mx-1" (click)="clickShowPage('shop/info', 'impressum');">Impressum</a>
    </div>
    <div class="mdb-color darken-1 py-3">
        <a class="mx-1" (click)="clickShowPage('shop/business', 'anmelden');">👩‍🍳Partner werden</a>
        <a *ngIf="!seller" class="mx-1" (click)="clickToNavigate('/a/login')">
            <span class="mr-2 d-lg-inline">Login</span>
            <i class="fas fa-user"></i>
        </a>
        <a *ngIf="seller" class="mx-1" (click)="clickToNavigate('/c/admin/seller-dashboard')">
            Dashboard
        </a>
        <a *ngIf="currentUser?.uid && currentUser?.emailId == _systemSettings.operator" class="mx-1" (click)="clickToNavigate('/c/admin/content-list')">
            Settings
        </a>
        <a *ngIf="seller" (click)="logout()" class="mx-1">
            <span class="mr-2 d-lg-inline">Logout</span>
            <i class="fas fa-user"></i>
        </a>
    </div>
    <!--/.Copyright-->
    <div class="footer-copyright py-3">
        <a class="mx-1" (click)="clickToNavigate('')"> © NGOAN Streetfood </a>
    </div>
    <!--/.Copyright-->
</footer>
<!--/.Footer-->