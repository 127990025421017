<main class="mt-4 pt-4">
    <div class="container text-center dark-grey-text mt-5">
        <section id="info_package">
            <ng-container *ngIf="busine">
                <h3 class="my-4 h3">Jetzt registrieren!</h3>
                <div class="row">
                    <div class="col-lg-6 mb-3 text-left">
                        <h4 class="mb-4">Hallo {{busine.name}},</h4>
                        <p>Erstellen Sie jetzt Ihr kostenloser Account und testen Sie 30 Tage kostenlos & unverbindlich mit allen Funktionen.</p>
                        <p>Testphase endet ohne Abonnement! Keine automatische Verlängerung! Mit einem kostenlosen Tarif ist der Einstieg danach ganz leicht.</p>
                        <p>Sie können jederzeit in Ihrem Account je nach Bedarf zu einem neuen Tarif zum Upgrade oder Downgrade wechseln.</p>
                        <p>Sie gewinnen neue Kunden und erhalten mehr Bestellungen. Wir unterstützen Sie leidenschaftlich dabei, Ihr Business voranzutreiben.</p>
                        <p>Haban Sie noch Frage? Wenden Sie sich an uns.<br> <strong>Email Support info(a)ngoan-streetfood.de</strong>.<br> Wir helfen Ihnen gerne dabei!</p>
                    </div>
                    <div class="col-lg-6 mb-3 text-left">
                        <div class="card o-hidden border-0 shadow-lg">
                            <div class="card-body p-1">
                                <div class="p-1">
                                    <fieldset class="border p-3">
                                        <legend class="w-auto text-muted"><small>Registrieren</small></legend>
                                        <form [formGroup]="registerForm" class="wt-form">
                                            <mat-form-field appearance="outline" class="wt-full-width">
                                                <input matInput type="text" maxlength="40" formControlName="displayName" id="displayName" [(ngModel)]="busine.businename" placeholder="Restaurantname" matNativeControl required>
                                                <mat-icon matSuffix>store</mat-icon>
                                                <mat-error *ngIf="f.displayName.hasError('required')"> Restaurantname ist erforderlich </mat-error>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="wt-full-width">
                                                <input matInput type="email" formControlName="email" id="email" [(ngModel)]="busine.email" placeholder="Email*" matNativeControl required>
                                                <mat-icon matSuffix>email</mat-icon>
                                                <mat-hint></mat-hint>
                                                <mat-error *ngIf="f.email.hasError('required')"> Email ist erforderlich
                                                </mat-error>
                                                <mat-error *ngIf="f.email.hasError('email')"> Email ist ungültig </mat-error>
                                            </mat-form-field>
                                            <mat-form-field appearance="outline" class="wt-full-width">
                                                <input matInput [type]="hide ? 'password' : 'text'" formControlName="password" id="password" value="" placeholder="Password">
                                                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                </button>
                                                <mat-error *ngIf="f.password.hasError('required')"> Password ist erforderlich
                                                </mat-error>
                                            </mat-form-field>
                                        </form>

                                        <mat-slide-toggle class="wt-full-width" [(ngModel)]="isApprove" name="isApprove">
                                            <small>Ich stimme</small>
                                        </mat-slide-toggle>
                                        <small>den <a href="{{setUrl('/shop/info/agb/')}}" target="_blank">AGB</a> und der <a
                                            href="{{setUrl('/shop/info/datenschutz/')}}"
                                            target="_blank">Datenschutzerklärung</a> zu. Die <a
                                            href="{{setUrl('/shop/info/agb/')}}" target="_blank">Widerrufsbelehrung</a> habe
                                        ich zur Kenntnis genommen.</small> <small *ngIf="isApprove"><sup class="green-text"><i
                                                class="fas fa-check"></i></sup></small>
                                        <span *ngIf="isApprove" class="mt-3">
                                            <a (click)="onSubmit()" class="btn btn-primary btn-user btn-block">
                                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Registrieren</a>
                                        </span>
                                    </fieldset>
                                    <div class="text-center">
                                        <strong></strong>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </ng-container>
        </section>
    </div>
</main>